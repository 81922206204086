import React from 'react'
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export const EventVenue = ({ formData, setForm, navigation }) => {

    const { eventVenueName, eventStreet, eventCity, eventState, eventZip } = formData;

    return (
        <Container maxWidth='xs'>
            <h3>Event Venue</h3>
            <p>Some default values have been provided for testing purposes</p>
            <TextField
                label="Address Line 1 / Venue Name"
                name="eventVenueName"
                value={eventVenueName}
                onChange={setForm}
                margin='normal'
                variant='outlined'
                autoComplete='off'
                fullWidth
            />
            <TextField
                label="Address Line 2 / Street"
                name="eventStreet1"
                value={eventStreet}
                onChange={setForm}
                margin='normal'
                variant='outlined'
                autoComplete='off'
                fullWidth
            />
            <TextField
                label="City"
                name="eventCity"
                value={eventCity}
                onChange={setForm}
                margin='normal'
                variant='outlined'
                autoComplete='off'
                fullWidth
            />
            <TextField
                label="State/Province/Region"
                name="eventState"
                value={eventState}
                onChange={setForm}
                margin='normal'
                variant='outlined'
                autoComplete='off'
                fullWidth
            />

            <TextField
                label="Zip/Postal Code"
                name="eventZip"
                value={eventZip}
                onChange={setForm}
                margin='normal'
                variant='outlined'
                autoComplete='off'
                fullWidth
            />

            <Button variant='contained'
                color='secondary'
                style={{ marginRight: '1rem', backgroundColor: '#EE8C00', color: 'white' }}
                onClick={() => navigation.previous()}>
                Previous
            </Button>
            <Button variant='contained'
                style={{ backgroundColor: '#EE8C00', color: 'white' }}
                onClick={() => navigation.next()}>
                Next
            </Button>


        </Container>
    )
}
