import React from 'react'
import { HashRouter as Router, Route, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import './App.css';

import HomePage from './pages/HomePage';
import { MultiStepForm } from './components/MultiStepForm';
import Footer from './components/Footer';
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  render() {
    return (
      <Router>

        <Container className='p-2' fluid={true} >

          <Navbar className="navbar navbar-light bg-light" bg='transparent' expand='lg'>
            <Navbar.Brand>

              <a className='navbar' style={{ color: '#EE8C00', textDecoration: 'none' }} href='/'>
                {/* <img className="d-inline-block align-top" src={eventhopperLogo} width='50' height='50' alt="" /> */}
                <h1 className='ml-2' style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>EventHopper</h1></a>

            </Navbar.Brand>

            <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
            <Navbar.Collapse id="navbar-toggle">
              <Nav className='ml-auto' >
                <Link className='nav-link h3' style={{ fontFamily: 'poppins', fontWeight: '500' }} to="/">HOME</Link>
                <Link className='nav-link h3 ml-3' style={{ fontFamily: 'poppins', fontWeight: '500' }} to='/event'>CREATE EVENT</Link>
              </Nav>
            </Navbar.Collapse>

          </Navbar>

          <Route path="/" exact render={() => <HomePage />} />
          <Route path="/event" exact render={() =>
            <MultiStepForm />
          } />
          <Footer >

          </Footer>

        </Container>
      </Router >
    );
  }
}

export default App;
