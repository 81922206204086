import React from 'react'
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetail from '@material-ui/core/AccordionDetails';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export const Review = ({ formData, navigation }) => {
    const { go } = navigation;
    const { eventName,
        eventDescription,
        eventStartDate,
        eventEndDate,
        eventVenueName,
        eventStreet,
        eventCity,
        eventState,
        eventZip, } = formData;

    const restOfLocation = `${eventCity}, ${eventState}, ${eventZip}`;
    const location = `${eventVenueName}\n${eventStreet ? eventStreet + '\n' + restOfLocation : restOfLocation}`;

    return (
        <Container maxWidth='sm'>
            <h3>Draft Event</h3>
            <p>This event <strong> will not be public until it is published</strong> from your event dashboard</p>
            <RenderAccordion summary="Review Event Details" id='details' go={go} details={[
                { 'Event Name': eventName },
                { 'Event Description': eventDescription },
                { 'Start Date': eventStartDate },
                { 'End Date': eventEndDate },
            ]} />

            <RenderAccordion summary="Review Location Details" id='location' go={go} details={[
                { 'Address': location },
            ]} />

            <Button variant='contained'
                style={{ marginTop: '1.5rem', backgroundColor: '#FF6600', color: 'white' }}
                onClick={() => go('submit')}>
                Submit
            </Button>
        </Container >
    )
}

export const RenderAccordion = ({ summary, details, go, id }) => {
    return <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {summary}
        </AccordionSummary>
        <AccordionDetail>
            <div>
                {details.map((data, index) => {
                    const objKey = Object.keys(data)[0];
                    const objValue = data[Object.keys(data)[0]]
                    return <ol> <h4>{`${objKey}: `}</h4><br />
                        {`${objValue} `}
                    </ol>

                })}
                <IconButton
                    onClick={() => go(`${id}`)}
                    color='#EE8C00'
                    component='span'>
                    <EditIcon />
                    Edit
                </IconButton>
            </div>
        </AccordionDetail>
    </Accordion>
}