import React from 'react'
import Container from '@material-ui/core/Container'
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti'

export const Submit = ({ formData }) => {
    const { eventName,
        eventDescription,
        eventStartDate,
        eventEndDate,
        eventVenueName,
        eventStreet,
        eventCity,
        eventState,
        eventZip, } = formData;
    const { width, height } = useWindowSize()
    return (
        <Container maxWidth="lg" style={{ marginTop: '2rem', marginBottom: '10rem' }}>

            <h1 className='pl-5 ml-5' style={{ text: 'orange' }}>
                New Event Drafted!!
            </h1>
            <p className='pl-5 ml-5'>This event is now ready for publishing</p>
            <br />
            <div className="center-block e-event-details" style={{ padding: '30px', width: '25%' }} >
                <h2>{eventName}<br /></h2>
                <p>{eventDescription}<br /><br /></p>
                <h5>{eventStartDate}     -    {eventEndDate}<br /><br /></h5>
                <h4>{eventVenueName}<br /></h4>
                <h4>{eventStreet}<br /></h4>
                <h4>{eventCity}, {eventState}, {eventZip}<br /></h4>
            </div >
            <Confetti numberOfPieces={70} >
                width={width}
                height={height}
            </Confetti>
        </Container >
    )
}
