/* eslint-disable no-unused-vars */
import { Box } from '@material-ui/core';
import React from 'react'
import { useForm, useStep } from 'react-hooks-helper';
import { EventInfo } from './eventCreationForm/EventInfo';
import { EventVenue } from './eventCreationForm/EventVenue';
import { Review } from './eventCreationForm/Review';
import { Submit } from './eventCreationForm/Submit';


const defaultData = {
    eventName: '',
    eventDescription: '',
    eventStart: '',
    eventEnd: '',
    eventVenueName: '',
    eventStreet: '',
    eventCity: '',
    eventState: '',
    eventZip: '',
}

const testingData = {
    eventName: "NBA Live 2K Tournament",
    eventDescription: "Come one, come all to the campus NBA Live vs 2K tournament where we battle it out across the various basketball games of old 'til the final 4 players reach today's game (2k21). Stay tuned for more details!",
    eventStart: new Date(),
    eventEnd: new Date(),
    eventVenueName: 'Harnwell College House',
    eventStreet: '3820 Locust Walk',
    eventCity: 'Philadelphia',
    eventState: 'PA',
    eventZip: '19104',
}

const steps = [
    { id: 'details' },
    { id: 'location' },
    { id: 'review' },
    { id: 'submit' },
]

export const MultiStepForm = () => {

    return <div className='pt-5'><RenderForm step props /></div>

}

const RenderForm = () => {
    const [formData, setForm] = useForm(testingData);
    const { step, navigation } = useStep({
        steps,
        initialStep: 0,
    });
    const props = { formData, setForm, navigation, }
    switch (step.id) {
        case steps[0].id:
            return <EventInfo {...props} />;
        case steps[1].id:
            return <EventVenue {...props} />;
        case steps[2].id:
            return <Review {...props} />;
        case steps[3].id:
            return <Submit {...props} />;
        default:
            break;
    }
    return;
}


