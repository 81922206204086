import React from 'react'
import Container from 'react-bootstrap/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export const EventInfo = ({ formData, setForm, navigation }) => {

    const { eventName, eventDescription, eventStartDate, eventEndDate } = formData;

    const setTime = (date) => {

    }

    return (
        <Container maxWidth='md' >
            <h3>Event Information</h3>
            <p>Some default values have been provided for testing purposes</p>
            <TextField
                size='small'
                label="Event Name"
                name="eventName"
                required
                value={eventName}
                error={eventName === ''}
                helperText='This field is required'
                onChange={setForm}
                margin='normal'
                variant='outlined'
                autoComplete='off'
                fullWidth
            />

            <TextField
                label="Event Description"
                name="eventDescription"
                multiline
                value={eventDescription}
                onChange={setForm}
                margin='normal'
                variant='outlined'
                autoComplete='off'
                fullWidth
                type=''
            />

            <TextField
                label="Event Start (UTC)"
                name="eventStartDate"
                value={eventStartDate}
                onChange={setForm}
                margin='normal'
                variant='outlined'
                autoComplete='off'
                fullWidth
                type='datetime-local'
                defaultValue={new Date()}
                InputLabelProps={{
                    shrink: true
                }}
            />

            <TextField
                label="Event End (UTC)"
                name="eventEndDate"
                value={eventEndDate}
                onChange={setForm}
                margin='normal'
                variant='outlined'
                autoComplete='off'
                fullWidth
                type='datetime-local'
                defaultValue={new Date()}
                InputLabelProps={{
                    shrink: true
                }}
            />

            <Button variant='contained'
                fullWidth
                style={{ marginTop: '5%', backgroundColor: '#EE8C00', color: 'white' }}
                onClick={() => navigation.next()}>
                Next
            </Button>

        </Container>
    )
}