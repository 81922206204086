import React from 'react'
import eventhopperLogo from '../assets/logo/eventhopper.gif'

export const HomePage = () => {
    return (
        <section id='main'>
            <div className='main-text ml-5 p-3 ' style={{ marginTop: -50 }}>
                <span className='k-span'>EventHopper.</span>
                <br /> where people discover amazing experiences
        </div>
            <img className='ml-5 p-3' src={eventhopperLogo} alt='eventhopper logo' height="300" style={{ paddingBottom: '1%' }} />
        </section>
    )
}

export default HomePage;